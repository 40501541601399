<template>
	<div class="List">
		<van-nav-bar title="业务员列表" left-arrow @click-left="onClickLeft">
		</van-nav-bar>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				finished-text="没有更多了"
				@load="onLoad"
				class="list-box"
			>
				<div v-for="(item, index) in list" :key="index">
					<van-row class="item">
						<van-col span="5"
							><p>{{ item.name }}</p></van-col
						>
						<van-col span="8"
							><p>{{ item.mobile }}</p></van-col
						>
						<van-col span="4">
							<van-button
								class="btn"
								type="info"
								size="mini"
								@click="editItem(item)"
								>修改</van-button
							>
						</van-col>
						<van-col span="5">
							<van-button
								class="btn"
								:class="[
									{ orange: item.status === 'OFF' },
									{ green: item.status === 'ON' }
								]"
								type="info"
								text-color="blue"
								@click="changeStatus(item)"
								>{{ item.status !== "OFF" ? "启用" : "禁用" }}</van-button
							>
						</van-col>
					</van-row>
					<div class="line"></div>
				</div>
			</van-list>
		</van-pull-refresh>
		<van-button
			class="create"
			type="primary"
			:disabled="!canCreate"
			block
			@click="createItem"
			>创建业务员</van-button
		>
	</div>
</template>
<script>
import Vue from "vue";
import Api from "@/api/user";
import { Toast } from "vant";
import { Col, Row } from "vant";

Vue.use(Col);
Vue.use(Row);
export default {
	data() {
		return {
			list: [],
			loading: false,
			finished: false,
			refreshing: false,
			canCreate: false,
			pageNo: 1
		};
	},
	methods: {
		//启用
		async changeStatus(item) {
			const thisStatusOn = item.status === "ON";
			this.$dialog
				.confirm({
					title: "更改员工状态",
					message: `您确定变更${thisStatusOn ? "禁用" : "启用"}工状态吗`
					// opts: async () => {
					//   const result = await Api.operatorUpdateStatus({
					//     operatorId: item.operatorId,
					//     status: thisStatusOn ? "OFF" : "ON",
					//   });
					//   if (result.success) {
					//     thisStatusOn ? (item.status = "OFF") : (item.status = "ON");
					//   }
					// },
				})
				.then(async () => {
					const result = await Api.operatorUpdateStatus({
						operatorId: item.operatorId,
						status: thisStatusOn ? "OFF" : "ON"
					});
					if (result.success) {
						thisStatusOn ? (item.status = "OFF") : (item.status = "ON");
					}
				})
				.catch();
		},
		editItem(item) {
			window.sessionStorage.setItem(
				"_edit_sale_item_data",
				JSON.stringify(item)
			);
			this.$router.push("/mine/salesman-list/create?onedit=onedit");
		},
		createItem() {
			this.$router.push({
				name: "createSalesman"
			});
		},
		async onLoad() {
			try {
				this.finished = false;
				this.loading = true;
				const { pageNo } = this;
				var res = await Api.findListByOrg({
					pageNo: pageNo
				});
				//debugger
				if (res) {
					this.canCreate = true;
				}
				const { data } = res;
				const { list } = data;
				if (this.pageNo == 1) {
					this.list = list;
				} else {
					this.list = [...this.list, ...list];
				}
				this.finished = !data.isNextPage;
				this.pageNo++;
				console.log(this.pageNo);
			} catch (error) {
				Toast.fail(error);
				this.list = [];
				this.finished = true;
			} finally {
				this.loading = false;
				this.refreshing = false;
			}
		},
		onRefresh() {
			this.pageNo = 1;
			this.onLoad();
		}
	}
};
</script>
<style lang="less" scoped>
.List {
	height: 100%;
	// display: flex;
	// flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
}
.create {
	position: fixed;
	bottom: 0px;
}
.list-box {
	padding: 0px 0px;
	.item {
		background: #fff;
		position: relative;
		display: flex;
		justify-content: space-between;
		padding: 0px 12px;
		align-items: center;
		p {
			height: 30px;
			line-height: 30px;
		}
		.btn {
			// height: 30px;
			// line-height: 30px;
		}
	}
	.line {
		height: 1px;
		background: #eaecf2;
	}
}
</style>
